import loadable from '@loadable/component';
import { EntityTypes, PageLoading, events, getEntityMeta, toEntity, categories, Organizer, organizers } from "@tawn33y/gigsflow-core";
import { config } from '@/config';

const ViewOrganizer = loadable(() => import('@tawn33y/gigsflow-core/dist/pages/ViewOrganizer/ViewOrganizer'), {
  resolveComponent: (components) => components.ViewOrganizer,
});

export default function ViewOrganizerPage({ organizer }: { organizer: Organizer }) {
  const { mediaBaseUrl } = config;

  const organizerEntity = toEntity(EntityTypes.ORGANIZER, mediaBaseUrl, organizer);
  const meta = getEntityMeta(organizerEntity);

  return (
    <ViewOrganizer
      events={events}
      categories={categories}
      organizer={organizer}
      fallback={(
        <PageLoading
          title={organizer.title}
          description={meta.description}
          keywords={meta.keywords}
          image={meta.image}
          url={meta.url}
        />
      )}
    />
  )
}

export async function getStaticPaths() {
  return {
    paths: organizers.map((organizer) => ({
      params: {
        slug: organizer.slug
      }
    })),
    fallback: false,
  };
}

export async function getStaticProps({ params }: any) {
  const organizer = organizers.find((organizer) => organizer.slug === params.slug);

  return {
    props: {
      organizer
    }
  }
}
